.boxChart{
    background-color: white;
    width: 420px;
    height: 370px;
}

.chartTitle{
    text-align: center;
    font-family: var(--lis-title);
    font-size: 30px;
    font-weight: bold;
    padding: 10px;
}



@media (max-width:1000px){
    .widthCustom {
      width: 100% !important;
    }
}

@media (min-width: 1200px) {
    .col-xl-2 {
        width: 12rem;
        gap: 1rem;
    } 
}