.whiteSpace {
  padding: 20px;

  background-color: white;
  margin: 10px;
}

.titleSpace {
  font-family: var(--lis-title);
  text-align: center;
  font-size: 30px;
  font-weight: 800;
}
