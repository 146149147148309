.boxSign {
  background-color: white;
  width: 450px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgPersonSignModal {
  border-radius: 100%;
  width: 100px !important;
  height: 100px;
}
.titleSignModal {
  font-family: var(--lis-title);
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0px;
}
.subtitleSignModal {
  font-family: var(--lis-title);
  font-size: 20px;
  font-weight: bold;
  color: grey;
  margin-bottom: 0px;
}

.sign_Button {
  margin-top: 20px !important;
  background-color: var(--aedas-green) !important;
  color: white !important;
  font-size: 12px !important;
  font-family: var(--lis-title) !important;
}

.sigCanvas {
  background-color: white;
  margin: 10px;
  border: 1px solid var(--blue-lis);
  border-radius: 5px;
}

.sign_header {
  font-family: var(--lis-title);
  text-align: center;
}

.sign_button {
  color: white;
  background-color: var(--aedas-green);
}

.toolbar {
  width: 500px !important;
}
