.selectDate {
  width: 350px;
  height: 70px;
  background-color: var(--blue-lis);
  color: white;
}

.companySelect {
  font-family: var(--lis-title);
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.selectDate > div > .MuiButtonBase-root > .MuiSvgIcon-root {
  fill: white !important;
}
