.titleTeams {
  font-family: var(--lis-title);
  text-align: center;
  font-weight: bold;
  padding: 20px;
}

.imgPerson{
  border-radius: 100%;
}
