.objetives_modal_Table {
  height: 500px;
  background-color: white;
}
.whiteBoxShowObjetives {
  width: 330px;
  height: 80px;
  background-color: white;
}
.imgPersonShowObjetives {
  border-radius: 100%;
  height: 100px;
  width: 100px;
  position: absolute;
  left: 341px;
}
.titleShowObjetivesName {
  width: 100%;
  font-size: 30px;
  font-weight: 800;
  font-family: var(--lis-title);
  text-align: center;
}
