.boxStatistics{
  background-color: white;
  height: 8rem;
  width: 230px;
}

.boxStatisticsHeader{
  background: var(--blue-lis);
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 4rem;
  min-height: 70px;
}

.boxStatisticsTitle{
  color: white;
  font-family: var(--lis-title);
  text-align: center;
  padding: 10px;
  font-size: 1.3rem;
  margin-bottom: 0;
}

.boxStatisticsValue{
  color: black;
  font-family: var(--lis-title);
  text-align: center;
  font-size: 1.5rem;
  margin: 1rem 0;
}