.imgPerson {
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: 20px;

}
.team_container {
  position: relative;
  width: 250px !important;
  height: 320px;
  margin: 10px;
  border: 1px solid rgba(0, 0, 0, 0.518);
  border-radius: 10px;
  background-color: white;
}
.team_container_self{
  position: relative;
  width: 250px !important;
  height: 280px;
  margin: 10px;
  border: 1px solid rgba(0, 0, 0, 0.518);
  border-radius: 10px;
  background-color: white;
}
.whiteBox {
  width: 100%;
  height: 215px;
  background-color: white;
  position: absolute;
  bottom: 0px;
  border-radius: 0px 0px 10px 10px;
}
.whiteBox_self {
  width: 100%;
  height: 170px;
  background-color: white;
  position: absolute;
  bottom: 0px;
  border-radius: 0px 0px 10px 10px;
}
.img_container {
  display: flex;
  justify-content: center;
  top: 130px;
  z-index: 1;
}

.titleNameTeam {
  padding-top: 55px;
  font-family: var(--lis-title);
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  margin: 0px;
  height: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitleNameTeam {
  font-family: var(--lis-body);
  text-align: center;
  font-size: 12px;
  color: grey;
}

.chipTeamStatus {
  width: fit-content;
  border-radius: 4px;
  color: white;
  font-family: var(--lis-title);
  text-align: center;
  padding-bottom: 24px;
  font-size: 12px;
  padding: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 15px;
}

.moreActionsTeam_container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 15px;
}
.teamManage_button {
  background-color: var(--blue-lis) !important;
  font-family: var(--lis-title) !important;
  font-size: 10px !important;
  color: white !important;
  margin-top: 10px !important;
}
