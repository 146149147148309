.boxReject {
  background-color: white;
  width: 650px;
  height: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgPersonRejectModal {
  border-radius: 100%;
  width: 100px !important;
  height: 100px;
}
.titleRejectModal {
  font-family: var(--lis-title);
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0px;
}
.subtitleRejectModal {
  font-family: var(--lis-title);
  font-size: 20px;
  font-weight: bold;
  color: grey;
  margin-bottom: 0px;
}

.reject_Button {
  margin-top: 20px !important;
  background-color: white !important;
  font-size: 12px !important;
  font-family: var(--lis-title) !important;
  color: var(--aedas-red) !important;
  border: 2px solid var(--aedas-red) !important;
}

.moreInfoReject {
  font-family: var(--lis-title);
}
