.orgchart.myChart {
  background-color: #f2f2f2 !important;
}

.orgchart-container {
  background-color: #f2f2f2;
}
.orgchart {
  background-image: none !important;
}
.orgchart.myChart > ul > li > ul li::before {
  border-top-color: #215a88;
}

.orgchart.myChart > ul > li > ul li .oc-node::before,
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
  background-color: #215a88;
}

.orgchart.myChart .oc-node .position {
  box-sizing: border-box;
  background-color: #215a88;
  color: #fff;
  width: 130px;
  height: 65px;
  padding: 2px;
}

.orgchart.myChart .oc-node .fullname {
  box-sizing: border-box;
  color: #215a88;
  background-color: #fff;
  width: 130px;
  height: 65px;
  padding: 2px;
  border: 1px solid #215a88;
}

.imgTree {
  border-radius: 100%;
  background-color: aqua;
  width: 70px !important;
  height: 70px;
}

.node {
  height: 200px;
  width: 150px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-left: 5px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
.row > * {
  padding: 0px !important;
}
.row {
  margin: 0px !important;
}
.orgchart .oc-node .oc-edge::before {
  border-color: rgba(0, 0, 0, 0.432) !important;
}
.orgchart ul li .oc-node.selected {
  background-color: rgba(255, 255, 255, 0) !important;
}
.orgchart .oc-node .topEdge {
  top: 5px;
}
.orgchart .oc-node .oc-edge::before {
  border-color: rgba(0, 0, 0, 0.432) !important;
}
.orgchart .oc-node .leftEdge {
  left: 14px;
}
.orgchart .oc-node .rightEdge {
  left: 136px;
}
.orgchart .oc-node .bottomEdge {
  top: 155px;
}
.orgchart ul li .oc-node:hover {
  background-color: rgba(0, 0, 0, 0.432) !important;
}
.orgchart-container {
  margin: 0px;
  background-color: #f2f2f2;
  border: 0px;
  height: 100%;
}
