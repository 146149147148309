.imgPersonHome {
  border-radius: 100%;
  height: 150px;
  width: 150px;
  position: absolute
}

.divBox {
  width: 70%;
  height: 640px;
  background-color: white;
}

.titleName {
  text-align: center;
  padding-top: 50px;
  font-family: var(--lis-title);
  font-size: 40px;
  font-weight: bold;
}

.subtitleRol {
  text-align: center;
  font-family: var(--lis-body);
  font-size: 20px;
  color: grey;
}


@media (max-height:850px){
  .titleName {
    text-align: center;
    padding-top: 8px;
    font-family: var(--lis-title);
    font-size: 40px;
    font-weight: bold;
  }
  .imgPersonHome {
    display: none !important;
  }
}

@media (max-width:1000px){
  .divBox {
    width: 90%;
    height: 640px;
    background-color: white;
  }
}