:root {
  --primary-color: white;
  --secondary-color: #0088a6;
  --blue-lis: #092e3b;
  --lis-ligthblue: #00b1e2;
  --aedas-green: #00b176;
  --toastify-color-success: #00b176 !important;
  --toastify-color-error: #b1003b !important;
  --aedas-red: #b1003b;
  --font-pay: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  --lis-title: "lis-title";
  --lis-body: "lis-body";
  --iconColor: black;
  --iconButtonSelected: #0088a6;
}

body {
  font-family: var(--lis-title), var(--lis-body);
  background-color: #8080801a !important;
  height: 100%;
}
@font-face {
  font-family: "lis-body";
  src: url(/public/Assets/Fonts/Heebo-VariableFont_wght.ttf);
}

@font-face {
  font-family: "lis-title";
  src: url(/public/Assets/Fonts/WorkSans-Medium.ttf);
}

.row {
  margin: 0px !important;
  padding: 0;
}

.row > * {
  padding: 0 !important;
}

main > div {
  height: 100%;
  width: 100%;
}

.react-tabs__tab {
  background-color: #092e3bad;
  color: white;
  font-family: var(--lis-title);
  text-align: center;
  border-radius: 0px !important;
  border-color: auto !important;
  width: 200px;
  margin-right: 20px;
}
.react-tabs__tab--selected {
  background-color: var(--blue-lis) !important;
  color: white !important;
  font-family: var(--lis-title) !important;
  border-radius: 0px !important;
  border-color: none !important;
  text-align: center;
  width: 200px;
  margin-right: 20px;
}
.MuiDataGrid-root {
  font-family: var(--lis-title);
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.react-tabs {
  height: 100%;
}

.react-tabs__tab-panel {
  height: 100%;
}
.modal-backdrop.show {
  z-index: 1201;
}
.modal {
  z-index: 1201 !important;
}
.Toastify__progress-bar--success {
  background: var(--aedas-green) !important;
}
.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}

.MuiDataGrid-columnHeaders {
  background-color: #092e3b;
  color: white;
}
.MuiDataGrid-columnHeader > .MuiDataGrid-columnSeparator > .MuiSvgIcon-root {
  fill: white !important;
}
.MuiDataGrid-columnHeaderDraggableContainer
  > .MuiDataGrid-menuIcon
  > .MuiButtonBase-root
  > .MuiSvgIcon-root {
  fill: white !important;
}


.MuiDataGrid-cell{
  justify-content: center !important;
}


.MuiDataGrid-iconButtonContainer > .MuiButtonBase-root > .MuiSvgIcon-root{
  fill: white !important;

}

.MuiDataGrid-columnHeaderTitleContainerContent > .MuiCheckbox-root > .MuiSvgIcon-root{
  fill: white !important;
}

.MuiDataGrid-footerContainer .MuiTablePagination-root.css-rtrcn9-MuiTablePagination-root p {
  margin-bottom: 0;
}


div > div > div.MuiDataGrid-main > div.MuiDataGrid-columnHeaders > div > div.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-columnHeaderCheckbox > div.MuiDataGrid-columnHeaderDraggableContainer > div > div > span > svg{
  display: none !important;
}
/* div > div > div > div.MuiDataGrid-columnHeaders > div > div.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-columnHeaderCheckbox > div.MuiDataGrid-columnHeaderDraggableContainer > div > div > span > input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



#pending_validation > span > span{
  position: absolute !important; 
  top: -11px !important; 
  right: -34px !important;
}

#pending_sign > span > span{
  position: absolute !important; 
  top: -11px !important; 
  right: -17px !important;
}