.showObjetives_Button {
  background-color: var(--blue-lis) !important;
  font-family: var(--lis-title) !important;
  font-size: 10px !important;
  color: white !important;
  margin-right: 10px !important;
  padding: 8px !important;
}

.signObjetives_Button {
  background-color: var(--aedas-green) !important;
  font-family: var(--lis-title) !important;
  font-size: 10px !important;
  color: white !important;
  padding: 8px !important;
  margin-right: 10px !important;
}

.rejectObjetives_Button {
  background-color: white !important;
  font-family: var(--lis-title) !important;
  font-size: 10px !important;
  color: var(--aedas-red) !important;
  padding: 8px !important;
  margin-right: 10px !important;
  border: 2px solid var(--aedas-red);
}
.signObjetives_table_container {
  width: 100%;
  height: 70vh;
  background-color: white;
}
