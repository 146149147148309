.boxPublish{
    background-color: white;
    padding:10px;
    text-align: center;
    
}


.publish_button{
    font-family: var(--lis-title) !important;
    background-color: var(--blue-lis) !important;
    color: white !important
}