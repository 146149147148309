.boxValidate {
  background-color: white;
  width: fit-content;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgPersonValidateModal {
  border-radius: 100%;
  width: 100px !important;
  height: 100px;
}
.titleValidateModal {
  font-family: var(--lis-title);
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0px;
}
.subtitleValidateModal {
  font-family: var(--lis-title);
  font-size: 20px;
  font-weight: bold;
  color: grey;
  margin-bottom: 0px;
}

.validate_Button {
  margin-top: 0.2rem !important;
  background-color: var(--aedas-green) !important;
  color: white !important;
  font-size: 12px !important;
  font-family: var(--lis-title) !important;
}
