.titleMyObjetives {
  font-family: var(--lis-title);
  font-weight: bold;
  padding: 20px !important;
}

.myObjetives_Table {
  margin: 0 auto;
  height: 90% !important;
  width: 90%;
  background-color: white;
  display: flex;
  justify-content: center;
}
