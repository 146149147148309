.currentObjetives_Table {
  height: 90%;
  background-color: white;
}

.inputPercentage{
  width: 100%;
  height: 100%;
  border: 0px !important;
}

.inputPercentageError{
  width: 100%;
  height: 100%;
  border: 4px solid red!important;
}

.asignacionTitle{
  padding: 1rem;
  background-color: white;
  width: fit-content;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  margin: 1rem auto;
}

.asignacionTitleError{
  padding: 1rem;
  background-color: white;
  width: 300px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: red
}